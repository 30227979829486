export default defineI18nConfig(() => {
  const { public: { LOCALES } } = useRuntimeConfig()

  return {
    legacy: false,
    strictMessage: false,
    escapeHtml: false,
    pluralRules: {
      ...LOCALES.reduce((pluralRulesForAllLocales: object, locale: string) => ({
        ...pluralRulesForAllLocales,
        [locale]: (choice: number, choicesLength: number) => {
          // Return plural when non-number e.g $t('daysText', '2 - 4')
          if (choice === -1) {
            return choicesLength - 1
          }

          choice = Math.abs(choice)

          if (choicesLength === 2) {
            return choice
              ? choice > 1
                ? 1
                : 0
              : 1
          }

          return choice ? Math.min(choice, 2) : 0
        }
      }), {})
    }
  }
})